var slideout = new Slideout({
    'panel': document.getElementById('panel'),
    'menu': document.getElementById('menu'),
    'padding': 256,
    'tolerance': 70,
    'side': 'right'
  });

slideout.on('beforeopen', function() {
  document.querySelector('.fixed').classList.add('fixed-open');
});

slideout.on('beforeclose', function() {
  document.querySelector('.fixed').classList.remove('fixed-open');
});

// Toggle button
      document.querySelector('.toggle-button').addEventListener('click', function() {
        slideout.toggle();
      });

$(window).scroll(function() {
  if ($(document).scrollTop() > 100) {
    $('nav').addClass('shrink');
    $('nav ul').removeClass('clear-both');
  } else {
    $('nav').removeClass('shrink');
    $('nav ul').addClass('clear-both');
  }
});